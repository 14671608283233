import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import "font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import './App.css';
import HomePage from './Pages/Home/home'; // HomePage component
import Valonans from './Pages/Valoan/valonans';
import About from './Pages/About/about';
import Pagenotfound from './Pages/Pagenotfound/pagenotfound';
import Elegibility from './Pages/Eligibility/eligibility';
import Faq from './Pages/Faq/faq';
import ThankYou from './Pages/Thankyou/thankyou';
import Benefits from './Pages/Benefits/benefits';
import Legal from './Pages/Legal/legal';
import TermsAndConditions from './Pages/Terms-and-condition/terms-and-conditions';
import PrivacyPolicy from './Pages/privacy-policy/privacy-policy';
import ContactUs from './Pages/contact-us/contact-us';
import QuoteForm from './Components/quoteform';



function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
         
          <Route path="/" element={<HomePage />} />

          <Route path="/valoans" element={<Valonans/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/benefit" element={<Benefits/>} />
          <Route path="/eligibility" element={<Elegibility/>} />
          <Route path="/faq" element={<Faq/>} />
          <Route path="*" element={<Pagenotfound />} />
          <Route path="/thankyou" element={<ThankYou />} />
          <Route path="/legal" element={<Legal/>}/>
          <Route path="/terms-and-conditions" element={<TermsAndConditions/>}/>
          <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
          <Route path="/contact-us" element={<ContactUs/>}/>
          <Route path="/quoteform" element={<QuoteForm/>}/>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
