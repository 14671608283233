import React from 'react';    
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import FaqBanner from '../../Components/faqbanner';
import FaqQuestions from '../../Components/faqquestions';





function Faq() {
    return (
        <>
            <Header /> 
          <FaqBanner />
          <FaqQuestions/>
   

            <Footer /> 
        </>
    );
}

export default Faq;

