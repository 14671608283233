import React from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Autoplay } from 'swiper/modules';
import banner1 from '../assets/images/banner1.jpg';
import banner2 from '../assets/images/valoanbannertwo.jpg';
import bannerImg from '../assets/images/bannerimg.svg';
import keyhome from '../assets/images/keyhomeicon.png'



function Banner() {
  return (
    <Swiper
      modules={[Navigation, Autoplay]}
      navigation
      autoplay={{ delay: 5000 }}
      loop
      className="bannerSwiper"
      autoHeight={true}
    >
      {/* <SwiperSlide>
        <div className="bannersection" style={{ backgroundImage: `url(${banner1})` }}>
          <div className="custom-container">
            <div className="bannerWrapper">
              <div className="bannerlhs">
                <div className="applyfottext">Apply for</div>
                <h1>VA Loans Online</h1>
                <p>Let Us Get You Home</p>
                <div className="bannercta">
                  <Link to="/quoteform" className="cta">Schedule a call</Link>
                </div>
              </div>
              <div className="bannerrhs">
                <div className="BannerImgparent">
                  <div className="ImgDiv">
                    <img src={bannerImg} alt="Banner Image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide> */}
      
      <SwiperSlide>
        <div className="bannersection" style={{ backgroundImage: `url(${banner2})` }}>
          <div className="custom-container">
            
             
              <div className=" bannerxyz">
                <div className="homekeyicon">
                <img src={keyhome} alt="Banner Image" />
                </div>
              <div className="applyfottext"></div>
                <h1>Serving Those Who Serve</h1>
                <p>We make homebuying smooth and stress-free, so you can focus on creating memories. Your dream home is just a click away.</p>
                <div className="bannercta">
                  <Link to="/quoteform" className="cta">Schedule a call</Link>
                </div>
              </div>
            
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
}

export default Banner;