import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import bannerImage from '../assets/images/aboutpagebanner.jpg'; // Import the image

function ContactUsForm() {
  useEffect(() => {
    const scriptSrc = "https://afno.salesmate.io/webforms.js";
    const formContainerId = "form-container";
    const formDivId = "_sm_webform_";
    const formId = "dae70c63-72f3-451d-b315-ce319e6406e4";

    const formContainer = document.getElementById(formContainerId);
    if (formContainer) {
      formContainer.innerHTML = ""; // Clear previous form if exists
      const formDiv = document.createElement("div");
      formDiv.id = formDivId;
      formContainer.appendChild(formDiv);
    }

    const loadForm = () => {
      if (window.SmFormSettings?.loadForm) {
        window.SmFormSettings.loadForm({
          formId,
          divId: formDivId,
          linkName: "afno.salesmate.io"
        });
      } else {
        console.error("SmFormSettings is not loaded properly.");
      }
    };

    if (!document.querySelector(`script[src="${scriptSrc}"]`)) {
      const script = document.createElement("script");
      script.async = true;
      script.src = scriptSrc;
      script.onload = loadForm; // Load form after script is loaded
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script); // Cleanup script on unmount
      };
    } else {
      loadForm();
    }
  }, []);

  return (
    <div>
      {/* Banner Section */}
      <div className="InnerBanner" style={{ backgroundImage: `url(${bannerImage})` }}>
        <div className="custom-container">
          <div className="InnerBannerWrapper">
            <h1>Contact Us</h1>
          </div>
        </div>
      </div>

      {/* Contact Information Section */}
      <div className="innerpage contactuspage">
        <div className="custom-container">
          <div className="contactusInforow">
            {/* Email Box */}
            <div className="ContactusInfoBox">
              <div className="infodflex">
                <div className="infodflexIcon">
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                </div>
                <div className="infodflexIcont">
                  <h4>Email Us</h4>
                  <p>
                    <a href="mailto:Jay@barrettfinancial.com">Jay@barrettfinancial.com</a>
                  </p>
                </div>
              </div>
            </div>

            {/* Phone Box */}
            <div className="ContactusInfoBox">
              <div className="infodflex">
                <div className="infodflexIcon">
                  <i className="fa fa-phone" aria-hidden="true"></i>
                </div>
                <div className="infodflexIcont">
                  <h4>Call Us</h4>
                  <p>
                    <Link to="tel:+16823078370">+1 682-307-8370</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* CRM Form Section */}
          <div className="FormSectionBox" id="form-container"></div>
        </div>
      </div>
    </div>
  );
}

export default ContactUsForm;
