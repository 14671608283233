import React from 'react';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import ContactUsForm from '../../Components/contactusform';




function ContactUs() {
    return (
        <>
            <Header />

            <ContactUsForm/>
            <Footer />
        </>
    );
}

export default ContactUs;
