import React from 'react';
import processimg1 from '../assets/images/processimg1.svg';
import processimg2 from '../assets/images/processimg2.svg';
import processimg3 from '../assets/images/processimg3.svg';
import processimg4 from '../assets/images/processimg4.svg';
import processimg5 from '../assets/images/processimg5.svg';

function OurProcess() {
  return (
    <div className="OurProcess">
      <div className="custom-container">
        <div className="titleh2 aligncenter">
          <h2>Our <span>Mortgage Process</span></h2>
        </div>
        <div className="OurProcessParent">
          {/* Step 1 */}
          <div className="OurProcessWrapper">
            <div className="OurProcessImg">
              <img src={processimg1} alt="Apply for Loan Online" />
            </div>
            <div className="OurProcessContent">
              <div className="Countofnum">01</div>
              <h4>Apply for Loan Online</h4>
              <ul>
                <li>Complete a loan application online.</li>
                <li>Average a 12-day clear to close.</li>
              </ul>
            </div>
          </div>

          {/* Step 2 */}
          <div className="OurProcessWrapper">
            <div className="OurProcessImg">
              <img src={processimg2} alt="Processing" />
            </div>
            <div className="OurProcessContent">
              <div className="Countofnum">02</div>
              <h4>Processing</h4>
              <ul>
                <li>Order escrow instructions, preliminary title report, appraisal, and verification of employment and funds.</li>
                <li>Average a 12-day clear to close.</li>
              </ul>
            </div>
          </div>

          {/* Step 3 */}
          <div className="OurProcessWrapper">
            <div className="OurProcessImg">
              <img src={processimg3} alt="Underwriting" />
            </div>
            <div className="OurProcessContent">
              <div className="Countofnum">03</div>
              <h4>Underwriting</h4>
              <ul>
                <li>Confirm your credit and income to qualify for the loan.</li>
                <li>Review escrow instructions, preliminary title report, and appraisal.</li>
                <li>Assign conditions to necessary parties (you may need to provide more supporting documents).</li>
              </ul>
            </div>
          </div>

          {/* Step 4 */}
          <div className="OurProcessWrapper">
            <div className="OurProcessImg">
              <img src={processimg4} alt="Clear to Close" />
            </div>
            <div className="OurProcessContent">
              <div className="Countofnum">04</div>
              <h4>Clear to Close</h4>
              <ul>
                <li>Once underwriting is complete, your loan is ready to close.</li>
                <li>We’ll schedule a closing date with you, and all your documents will be signed.</li>
              </ul>
            </div>
          </div>

          {/* Step 5 */}
          <div className="OurProcessWrapper">
            <div className="OurProcessImg">
              <img src={processimg5} alt="Closing" />
            </div>
            <div className="OurProcessContent">
              <div className="Countofnum">05</div>
              <h4>Closing</h4>
              <ul>
                <li>Title/Escrow faxes or emails signed loan documents to the lender.</li>
                <li>Lender reviews signed documents, approves funding, and then wires funds to title/escrow.</li>
                <li>Title/Escrow disburses funds.</li>
                <li>Title records the grant deed in the buyer’s name.</li>
                <li>Escrow provides a final audit of disbursed funds.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurProcess;
