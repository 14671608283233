import React from 'react';
// import aboutBanner from '../assets/images/aboutpagebanner.jpg';
import aboutBanner from '../assets/images/valoanbannertwo.jpg'; // Adjust the path based on your project structure

function AbtBanner() {
  return (
    <div
      className="InnerBanner"
      style={{ backgroundImage: `url(${aboutBanner})` }}>
      <div className="custom-container">
        <div className="InnerBannerWrapper">
          <h1>Serving Those Who Serve</h1>
        </div>
      </div>
    </div>
  );
}

export default AbtBanner;
