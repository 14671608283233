import React from 'react';

function MortageCalc() {
  return (
    <>
      <div className="calculator-widget" id="mortagesx">
        <div className="custom-container">
          <div class="titleh2 alignenter"><h2>Mortage Calculator
          </h2>
          <p>Calculate monthly mortgage payment, taxes, and insurance, and plan a budget that fits your financial goals.</p></div>
          <iframe
            title="Mortgage Payment Calculator"
            src="https://www.primcomortgage.com/calculator/iframe-widget?HighchartColumnInterest=262526&HighchartColumnPrincipal=85a3b3&HighchartPieInterest=262526&HighchartPiePrincipal=85a3b3&HighchartPieInsurance=658693&HighchartPieMortgageInsurance=dddad7&ButtonColor=85a3b3&HighlightTextColor=85a3b3&HighlightTextColorLight=85a3b3&HeaderColor=85a3b3&NavigateMode=true&CalcMode=Mortgage&Footnote=true&AmortTab=true&lo=false&lo-name=&lo-title=&lo-nmls=&lo-location=,&lo-email=&lo-phone=&lo-imageurl=&lo-applynow=&lo-mysite=&lo-logo="
            width="100%"
            height="1000"
            frameBorder="0"
            style={{ overflow: 'hidden', scrollbarWidth: 'none', msOverflowStyle: 'none' }}
          ></iframe>
        </div>
      </div>

    </>
  );
}

export default MortageCalc;
