import React from "react";
import Header from '../../common/Header';
import Footer from '../../common/Footer';

const Legal = () => {
    return (
        <>
          <Header /> 
          <div className="InnerBanner"
      style={{ backgroundImage: "url('Images/legalsss.jpg')", backgroundSize: "cover", backgroundPosition: "center" }}>
        <div className="custom-container">
          <h1>Legal</h1>
        </div>
      </div>
            <div className="innerpage Privacypolicypage">
                <div className="custom-container">
                    <div className="PrivacyPolicyWrapper">
                        <section>
                            <h4>Legal Disclaimer</h4>
                            <p>
                                We hereby authorize you to view and print information on this website subject to it being used for informational and non-commercial purposes.
                            </p>
                            <p>The information contained in this website is believed to be reliable, but we do not warrant its completeness, timeliness or accuracy. The information on this website is not intended as an offer or solicitation for any mortgage product or any financial instrument. The information and materials contained in this website - and the terms and conditions of the access to and use of such information and materials - are subject to change without notice. Products and services described may differ among geographic locations, offices and as a result of individual conditions. Not all products and services are offered at all locations. In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.</p>
                            <p>It is our intention that data provided on a subject is of a general nature. Our website does not represent an exhaustive treatment of subjects nor is the information intended to constitute accounting, tax, legal, consulting or other professional advice.</p>
                            <p>Prior to making any decision or taking any action we kindly request you to contact your tax or legal advisors.</p>
                            <p>Please use this document and information at your own risk. The content of this site is copyrighted and therefore any unauthorized use of any materials on this website may violate copyright, trademark, and other laws.</p>
                            <p>Materials on this website may not be modified, reproduced, or publicly displayed, distributed or performed for any public or commercial purposes prior to our approval.</p>

                        </section>
















                    </div>
                </div>
            </div>
            <Footer /> 
        </>
    );
};

export default Legal;
