import React from 'react';
import missionIcon from '../assets/images/missionicon.png';
import visionIcon from '../assets/images/vissionicon.png';

function OurVision() {
  return (
    <div className="ourvisionmissionsec">
      <div className="custom-container">
        <div className="titleh2 aligncenter">
          <h2>Our Mission & Vision</h2>
        </div>
        <div className="ourvisionmissionsecrow">
          {/* Mission Section */}
          <div className="ourvisionmissionBox">
            <div className="ourvmIcon">
              <img src={missionIcon} alt="Mission Icon" />
            </div>
            <div className="ourvmcontent">
              <h4>Our Mission</h4>
              <p>
                To empower Veterans & Service Members to achieve their dreams of homeownership 
                by providing innovative and affordable mortgage solutions.
              </p>
            </div>
          </div>

          {/* Vision Section */}
          <div className="ourvisionmissionBox">
            <div className="ourvmIcon">
              <img src={visionIcon} alt="Vision Icon" />
            </div>
            <div className="ourvmcontent">
              <h4>Our Vision</h4>
              <p>
                To make homeownership simple, fast, and accessible for our Veterans & Service Members.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurVision;
