import React from "react";
import { Link } from "react-router-dom";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import notFoundImg from "../../assets/images/page-not-found.jpg"; // Import the image correctly

function Pagenotfound() {
  return (
    <>
      <Header /> {/* Navbar */}

      <div className="InnerPage">
        <div className="Pagenotfoundmain">
          <div className="container">
            <div className="notfoundpage">
              {/* Page Not Found Image */}
              <div className="notfoundpageImg">
                <img
                  src={notFoundImg}
                  alt="Page not found illustration"
                  role="img"
                />
              </div>

              {/* Page Not Found Text */}
              <div className="notfoundpageText">
                <h4>Page Not Found</h4>
                <p>Sorry, the page you’re looking for does not exist.</p>
                <Link to="/" className="cta">
                  Back to Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer /> {/* Footer */}
    </>
  );
}

export default Pagenotfound;
