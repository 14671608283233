import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo.svg';
import barrettLogo from '../assets/images/barrett.png';

function Header() {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const handleOpenMenu = () => {
    document.body.classList.add('sidebarMenuOpen');
  };

  const handleCloseMenu = () => {
    document.body.classList.remove('sidebarMenuOpen');
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <header>
        <div className="headerMainWrapper">
          <div className="menuHeader sidebar" id="sidebar">
            <div className="custom-container">
              <div className="menuHeaderinner">
                <div className="barrret-logox">
                  <div className="logoheader">
                    <Link to="/" onClick={handleScrollToTop}>
                      <img src={logo} alt="Logo" />
                    </Link>
                  </div>
                  <div className="barretlogo">
                    <img src={barrettLogo} alt="Barret Logo" />
                  </div>
                </div>

                <div className="Headertside">
                  <div className="headermenuparent">
                    <div className="headerrhscontent">
                      <div className="FormobmenuHeader">
                        <div className="FormobmenuHeaderWrapper">
                          <div className="logo">
                            <Link to="/" onClick={handleScrollToTop}>
                              <img src={logo} alt="Logo" />
                            </Link>
                          </div>
                          <button className="closeIcon" onClick={handleCloseMenu}></button>
                        </div>
                      </div>

                      <nav>
                        <ul className="mainULnav">
                          <li>
                            <Link to="/" onClick={handleScrollToTop}>
                              Home
                            </Link>
                          </li>

                          <li className="dropdownMenu" onClick={toggleDropdown}>
                            <Link to="/valoans" onClick={handleScrollToTop}>
                              VA Loans
                              <span className={`dropdown-icon ${isDropdownOpen ? "open" : ""}`}></span>
                            </Link>
                            <ul className={`sub-menu ${isDropdownOpen ? "open" : ""}`}>
                              <li>
                                <Link to="/benefit" onClick={handleScrollToTop}>
                                  Benefits
                                </Link>
                              </li>
                              <li>
                                <Link to="/eligibility" onClick={handleScrollToTop}>
                                  Eligibility
                                </Link>
                              </li>
                            </ul>
                          </li>

                          <li>
                            <Link to="/about" onClick={handleScrollToTop}>
                              About
                            </Link>
                          </li>
                          <li>
                            <Link to="/contact-us" onClick={handleScrollToTop}>
                              Contact Us
                            </Link>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>

                  <div className="HeaderMenuRhs">
                    <div className="Headercta">
                      <Link to="/contact-us" className="cta" onClick={handleScrollToTop}>
                        Apply Now
                      </Link>
                    </div>

                    <div className="hamburgerMenuBtn" onClick={handleOpenMenu}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="menuOverlay" onClick={handleCloseMenu}></div>
        </div>
      </header>
    </>
  );
}

export default Header;
