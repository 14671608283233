import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../../common/Header';
import Footer from '../../common/Footer';
import thankyouimg from '../../assets/images/thankyou.jpg';

function ThankYou() {
    return (
        <>
            <Header />

            <div className="InnerPage">
                <div className="Pagenotfoundmain">
                    <div className="container">
                        <div className="notfoundpage">
                            <div className="notfoundpageImg">
                                {/* Ensure image is loading properly */}
                                <img src={thankyouimg} alt="Thank You Illustration" onError={(e) => e.target.style.display = 'none'} />
                            </div>
                            <div className="notfoundpageText">
                                <h4>Thank You!</h4>
                                <p>We have received your message and will get back to you as soon as possible.</p>
                                <Link to="/" className="cta">Back to home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}

export default ThankYou;
