import React, { useEffect } from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";

const QuoteForm = () => {
    useEffect(() => {
        const scriptSrc = "https://afno.salesmate.io/webforms.js";
        const formContainerId = "form-container";
        const formDivId = "_sm_webform_";
        const formId = "dae70c63-72f3-451d-b315-ce319e6406e4";

        // Remove any existing form before adding a new one
        const formContainer = document.getElementById(formContainerId);
        if (formContainer) {
            formContainer.innerHTML = ""; // Clear previous form if exists
            const formDiv = document.createElement("div");
            formDiv.id = formDivId;
            formContainer.appendChild(formDiv);
        }

        // Function to load the form when SmFormSettings is ready
        const loadForm = () => {
            if (window.SmFormSettings?.loadForm) {
                window.SmFormSettings.loadForm({
                    formId,
                    divId: formDivId,
                    linkName: "afno.salesmate.io"
                });
            } else {
                console.error("SmFormSettings is not loaded properly.");
            }
        };

        // Check if script is already loaded
        if (!document.querySelector(`script[src="${scriptSrc}"]`)) {
            const script = document.createElement("script");
            script.async = true;
            script.src = scriptSrc;
            script.onload = loadForm; // Load form after script is loaded
            document.body.appendChild(script);
        } else {
            // If script already exists, try loading form directly
            loadForm();
        }
    }, []);

    return (
        <>
            <Header />
            <div className="InnerBanner" style={{ backgroundColor: "#000" }}>
                <div className="custom-container">
                    <div className="InnerBannerWrapper">
                        <div className="innerbannersubhd">Get a No-Obligatory Pre-approval Letter</div>
                    </div>
                </div>
            </div>

            <div className="innerpage contactuspage">
                <div className="custom-container">
                    {/* CRM Form */}
                    <div className="FormSectionBox" id="form-container"></div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default QuoteForm;
