import React, { useEffect, useRef } from 'react';
import tabImg1 from '../assets/images/tabimg1.svg';
import tabImg2 from '../assets/images/tabimg2.svg';
import tabImg3 from '../assets/images/tabimg3.svg';
import tabImg4 from '../assets/images/tabimg4.svg';
import tabImg5 from '../assets/images/tabimg5.svg';

function CoreValue() {
  const tabLinksRef = useRef([]);
  const tabsRef = useRef([]);
  const autoplayRef = useRef(null);
  const autoplayInterval = 3000; // Interval time in milliseconds

  useEffect(() => {
    let currentIndex = 0;

    function switchTab(index) {
      tabLinksRef.current.forEach(link => link?.classList.remove('active'));
      tabsRef.current.forEach(tab => tab?.classList.remove('active'));

      tabLinksRef.current[index]?.classList.add('active');
      tabsRef.current[index]?.classList.add('active');
    }

    function autoSwitch() {
      currentIndex = (currentIndex + 1) % tabsRef.current.length;
      switchTab(currentIndex);
    }

    // Start autoplay
    autoplayRef.current = setInterval(autoSwitch, autoplayInterval);

    // Event listeners for manual tab switching
    tabLinksRef.current.forEach((link, index) => {
      link?.addEventListener('click', () => {
        clearInterval(autoplayRef.current); // Stop autoplay
        switchTab(index);
        currentIndex = index; // Update index
        autoplayRef.current = setInterval(autoSwitch, autoplayInterval); // Restart autoplay
      });
    });

    // Cleanup function
    return () => {
      clearInterval(autoplayRef.current);
      tabLinksRef.current.forEach((link, index) => {
        link?.removeEventListener('click', () => switchTab(index));
      });
    };
  }, []);

  return (
    <div className="tabSectionmain">
      <div className="custom-container">
        <div className="titleh2 alignenter">
          <h2>Our Core Values</h2>
        </div>
        <div className="Tab__Section">
          <div className="tabs">
            <div className="tabnav">
              {["Change", "Selflessness", "Hardwork", "Truth", "Teamwork"].map((value, index) => (
                <div
                  key={index}
                  ref={el => (tabLinksRef.current[index] = el)}
                  className={`tab-links ${index === 0 ? 'active' : ''}`}
                >
                  <div className="coreveluBox">
                    <div className="corevaluecountdiv">{index + 1}</div>
                    <div className="corevaluetextdiv">{value}</div>
                  </div>
                </div>
              ))}
            </div>

            <div className="tab-content">
              {[
                { text: "Change is the only constant. We encourage sharing ideas and thoughts so we can continue to innovate and serve our communities better.", img: tabImg1 },
                { text: "We believe in selfless service and the only reason for our existence is for the betterment of society.", img: tabImg2 },
                { text: "We believe hard work beats talent when talent does not work hard. We consistently work hard and strive to acquire new skills to provide innovative, cost-effective solutions, and exceed our customers' expectations.", img: tabImg3 },
                { text: "We believe in transparency, honesty, and doing the right thing. We will always take the hard right vs. the easy wrong.", img: tabImg4 },
                { text: "We cannot make a measurable impact without teamwork. We practice empathy as a team. Teamwork brings together different skills and perspectives which allow us to expand our horizons, adapt to the changing business environment, and achieve our goals.", img: tabImg5 },
              ].map((item, index) => (
                <div
                  key={index}
                  ref={el => (tabsRef.current[index] = el)}
                  className={`tab ${index === 0 ? 'active' : ''}`}
                >
                  <div className="tabdatarowparent">
                    <div className="tabdatalhs">
                      <p>{item.text}</p>
                    </div>
                    <div className="tabdatarhs">
                      <img src={item.img} alt={item.text} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CoreValue;
