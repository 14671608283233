import React from 'react';

function HowVAloansWork() {
  return (
    <>
      <div className="ContentWithVideo">
        <div className="custom-container">
          <div className="titleh2">
            <h2>How VA Loans Work?</h2>
          </div>
          <div className="ContentWithVideoWrapper">
            <div className="ContentWithVideolhs">
              <p>
                Purchasing the first home is one of the biggest achievements for any individual or family. However, for
                some, the process of VA loans can be complex. That’s why we are always ready to help you with VA loans and
                answer all your queries. Please feel free to call us to apply for a VA loan or to learn more about it.
              </p>
              <p>
                VA loans are a mortgage option for eligible veterans, service members, and military families to purchase
                a primary residential property or refinance an existing mortgage.
              </p>
              <p>
                VA loans work differently than conventional mortgages. The Department of Veterans Affairs only oversees
                VA loans and doesn’t issue loans but it guarantees a portion for each VA loan against default. This
                guarantee helps private VA lenders to offer $0 down financing, lower rates, and better terms.
              </p>
            </div>
            {/* ContentWithVideolhs */}
            <div className="ContentWithVideorhs">
              <div className="imgwrapper">
                <div className="sixrationine"></div>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/fJ7lDSo4veI?si=uRxIm871nPPeqerX"
                  title="How VA Loans Work - YouTube Video"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            {/* ContentWithVideorhs */}
          </div>
          {/* ContentWithVideoWrapper */}
        </div>
        {/* custom-container */}
      </div>
      {/* ContentWithVideo */}
    </>
  );
}

export default HowVAloansWork;
