import React, { useState } from 'react';

const faqData = [
  {
    question: 'What is a VA Loan?',
    answer: `A VA Loan is a home loan program for veterans, created by the Veteran Administration in 1944 under the Servicemen's Readjustment Act (also known as the GI Bill). Signed into law by President Franklin D. Roosevelt, this program offers U.S. government-guaranteed loans with no down payment for eligible veterans. These loans are issued by private lenders to help veterans buy homes to live in. The lenders are protected against loss in case of default.`,
  },
  {
    question: 'Who can apply for a VA Loan?',
    answer: `The eligibility for a VA loan depends on your service history and duty status. To check the eligibility for a VA loan, please visit [Link to VA Loan Eligibility Page].`,
  },
  {
    question: 'What type of property can I purchase with a VA loan?',
    answer: `A VA loan can be used to purchase a primary residence within the United States and its territories. You can buy the following types of properties with a VA loan:`,
    list: [
      'Existing single-family homes',
      'Townhouses or Condominiums in a VA-approved Project',
      'Manufactured Homes or Lots',
      'New Construction Homes',
    ],
  },
  {
    question: 'What are the advantages of a VA loan?',
    answer: `VA loans offer several unique benefits that you won’t get with other loan options.`,
    list: [
      '100% Financing with Zero Down Payment',
      'No Private Mortgage Insurance (PMI)',
      'No Prepayment Penalties',
      'Lower Rates',
      'Easier qualification process',
      'Lifetime Benefit (reusable for other homes)',
      'Option to combine with a down payment for reduced closing costs',
    ],
  },
  {
    question: 'How do I apply for a VA loan?',
    answer: `You can apply for a VA guaranteed loan with any VA-approved mortgage lender. Apart from application requirements defined by your lender, you will also need:`,
    list: [
      'Certificate of Eligibility (COE) from the Veterans Administration',
      'Proof of Military Service issued by a VA Eligibility Center',
    ],
    link: 'Apply for a VA Loan',
    linkHref: '#',
  },
  {
    question: 'Can I apply for another VA loan if I already have one VA loan?',
    answer: `Yes, you can get another VA loan, depending on your specific situation. If you’ve paid off your previous VA loan and no longer own the property, you can be eligible for another VA loan. Also, on a one-time basis, you may restore your eligibility if your prior VA loan has been paid off but you still own the property. In both cases, you need to submit a completed VA Form 16-1880 to VA Eligibility Center along with proof that the previous loan has been fully paid off.`,
    link: 'Talk to our VA Loan Specialist',
    linkHref: '#',
  },
  {
    question: 'What are the disadvantages of VA loans?',
    answer: `VA Loans made prior to March 1, 1988 can be assumed with no qualifying of the new buyer. If the buyer defaults, the original Veteran homeowner might still be liable for the funds. Some sellers may be hesitant to work with VA loans because they can take longer to process compared to conventional loans. VA loans sometimes require sellers to pay a portion of the closing costs, which might make sellers less willing to negotiate the sale price.`,
  },
];

const FaqSection = () => {
  const [activeIndex, setActiveIndex] = useState(null); // State to track active FAQ item

  const handleToggleActive = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index)); // Toggle active state
  };

  return (
    <div className="innerpage">
      <div className="custom-container">
        <div className="FaqSection">
          <div className="FaqWrapper">
            {faqData.map((faq, index) => (
              <div
                className="Faqrow" // Add active class conditionally
                key={index}
              >
                <div
                  className={`FaqLabel ${activeIndex === index ? 'active' : ''}`} // Add active class to label as well
                  onClick={() => handleToggleActive(index)} // Toggle active class on click
                >
                  <h3>{faq.question}</h3>
                </div>
                {activeIndex === index && ( // Only show content for active FAQ
                  <div className="FaqContentBox">
                    <div className="FaqContentBoxInner">
                      <p>{faq.answer}</p>
                      {faq.list && (
                        <ul>
                          {faq.list.map((item, idx) => (
                            <li key={idx}>{item}</li>
                          ))}
                        </ul>
                      )}
                      {faq.link && (
                        <a href={faq.linkHref} className="cta" target="_blank" rel="noopener noreferrer">
                          {faq.link}
                        </a>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqSection;
