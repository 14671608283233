import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser';

// Importing images
import logo from '../assets/images/logo.svg';
import heimloanFooterImg from '../assets/images/heimloan-img-footer.png';

function Footer() {
  const currentYear = new Date().getFullYear();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: ''
  });
  const [responseMessage, setResponseMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false); // "Go to Top" button visibility

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true); // Show loader

    emailjs
      .sendForm('service_441q9ab', 'template_a04orrr', e.target, 'pR98nU060XagoAbxV')
      .then(
        (result) => {
          console.log('Email sent successfully:', result.text);
          setResponseMessage('Mail sent successfully!');
          setIsLoading(false);
        },
        (error) => {
          console.error('Error sending email:', error.text);
          setResponseMessage('Oops! Something went wrong. Please try again.');
          setIsLoading(false);
        }
      );

    setFormData({
      name: '',
      email: '',
      phone: ''
    });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <footer>
      <div className="custom-container">
        <div className="footerMenu">
          {/* FooterWidget */}
          <div className="FooterWidget">
            <h4>Quick Links</h4>
            <ul>
              <li><Link to="/" onClick={scrollToTop}>Home</Link></li>
              <li><Link to="/valoans" onClick={scrollToTop}>VA Loans</Link></li>
              <li><Link to="/about" onClick={scrollToTop}>About</Link></li>
              <li><Link to="/faq" onClick={scrollToTop}>FAQ</Link></li>
              <li><Link to="/legal" onClick={scrollToTop}>Legal</Link></li>
              <li><Link to="/privacy-policy" onClick={scrollToTop}>Privacy Policy</Link></li>
              <li><Link to="/terms-and-conditions" onClick={scrollToTop}>Terms and Conditions</Link></li>
            </ul>
          </div>

          {/* FooterWidget */}
          <div className="FooterWidget">
            <div className="footerLogo">
              <Link to="/">
                <img src={logo} alt="Company Logo" />
              </Link>
            </div>
            <div className="contactInfo">
              <div className="ContactInfoWrap">
                <div className="ContactInfoText">NMLS: 181106</div>
              </div>
              <div className="ContactInfoWrap">
                <div className="ContactInfoIcon">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                </div>
                <div className="ContactInfoText">
                  <Link to="https://maps.app.goo.gl/XvwH1wcBUzQc48C4A">
                    275 E Rivulon Blvd, Suite 200 Gilbert AZ 85297
                  </Link>
                </div>
              </div>
              <div className="ContactInfoWrap">
                <div className="ContactInfoIcon">
                  <i className="fa fa-phone" aria-hidden="true"></i>
                </div>
                <div className="ContactInfoText">
                  <Link to="tel:+16823078370">+1 682-307-8370</Link>
                </div>
              </div>
              <div className="ContactInfoWrap">
                <div className="ContactInfoIcon">
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                </div>
                <div className="ContactInfoText">
                  <Link to="mailto:Jay@barrettfinancial.com">Jay@barrettfinancial.com</Link>
                </div>
              </div>
              <div className="ContactInfoWrap">
                <div className="ContactInfoIcon">
                  <i className="fa fa-globe"></i>
                </div>
                <div className="ContactInfoText">
                  <p>
                    <a href="https://nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/181106" rel="noopener noreferrer" target="_blank">
                      NMLS Consumer Access
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Full Width Footer */}
        <div className="full-wdth-foot">
          <p>
          Jay Kumar Agrawal | NMLS #2212131 | Barrett Financial Group, L.L.C. | NMLS #181106 | 275 E Rivulon Blvd, Suite 200, Gilbert, AZ 85297 |AL 22977 | AR 124815 | AZ 0904774 | CA 60DBO-46052 & 41DBO-148702 Licensed by Dept. of Financial Protection & Innovation under the California Residential Mortgage Lending Act. Loans made or arranged pursuant to a California Financing Law License | CO | FL MLD1880 | GA 181106 |IA 2021-0157 | IL MB.6761630 view reinvestment notice barrettfinancial.com/illinois-reinvestment | KS MC.0025726 | KY MB757252 & MC763707 | MD 181106 |ME 181106 | MI fl0022342 | MN MN-MO-181106 | MO 181106 |NC B-203722 | OH RM.804600.000 | OK ML013880 | OR |PA 92105 | TN 204577 | TX view complaint policy at barrettfinancial.com/texas-complaint |VA MC-7357 & MC-7357 | WA MB-181106 | Equal Housing Opportunity | This is not a commitment to lend. All loans are subject to credit approval. | NMLS Consumer Access | Visit :<a href="https://www.barrettfinancial.com/"> Barrett Financial Group’s Website</a>
          
          </p>
        </div>
      </div>

      {/* SubFooter */}
      <div className="SubFooter">
        <div className="custom-container">
          <div className="SubFooterWrapper">
            <div><p>Copyright © {currentYear} The American Loan Company</p></div>
            <div className="heimloan-footr-img">
              <img src={heimloanFooterImg} alt="Loan Products" />
            </div>
            <div className="SocialIcons">
              <Link to="#" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook" aria-hidden="true"></i></Link>
              <Link to="#" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter" aria-hidden="true"></i></Link>
              <Link to="#" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin" aria-hidden="true"></i></Link>
              <Link to="#" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram" aria-hidden="true"></i></Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
