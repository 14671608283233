import React from 'react';
import { Link } from 'react-router-dom';

function EligibilityBanner() {
  return (
    <div
      className="InnerBanner"
      style={{ backgroundImage: "url('../assets/images/valoansbanner.jpg')" }}
    >
      <div className="custom-container">
        <div className="InnerBannerWrapper">
          <h1>Check Your VA Loan Eligibility</h1>
          <p>
            Millions of veterans and service members have found their dream homes through VA loans. Contact us to see if you qualify for a VA loan.
          </p>
          <div className="bannercta">
            <Link to="/quoteform" className="cta">Schedule a call</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EligibilityBanner;
