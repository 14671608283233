import React from 'react';
import Banner from '../../Components/banner';
import Serving from '../../Components/serving';       
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import OurProcess from '../../Components/OurProcess';
import MortageCalc from '../../Components/mortagecalc';
import WhyUs from '../../Components/whyus';

function HomePage() {
    return (
        <>
            <Header /> {/* Render the Header component */}
            <Banner />
            <Serving/>
            <WhyUs/>
            <OurProcess/>
            <MortageCalc/>
            <Footer /> 
        </>
    );
}

export default HomePage;
