import React from "react";
import chooseImg from "../assets/images/chooseimg.svg";
import chooseIcon1 from "../assets/images/chooseicon1.svg";
import chooseIcon2 from "../assets/images/chooseicon2.svg";
import chooseIcon3 from "../assets/images/chooseicon3.svg";

const WhyUs = () => {
  return (
    <div className="WhyChooseSec">
      <div className="custom-container">
        <div className="titleh2">
          <h2>Why Choose Us?</h2>
        </div>
        <div className="WhyChooseSecWrapper">
          {/* Left Side Image */}
          <div className="WhyChooselhs">
            <img src={chooseImg} alt="Why Choose Us Illustration" />
          </div>

          {/* Right Side Content */}
          <div className="WhyChooserhs">
            <div className="WhyChooselistparent">
              {/* List Item 1 */}
              <div className="WhyChooselist">
                <div className="WhyChooseIcon">
                  <img src={chooseIcon1} alt="Fast Process Icon" />
                </div>
                <div className="WhyChooseUi">
                  <h4>We Move Fast</h4>
                  <ul>
                    <li>We’re mortgage experts.</li>
                    <li>Average a 12-day clear to close.</li>
                    <li>Smooth, fast, and cost-effective.</li>
                  </ul>
                </div>
              </div>

              {/* List Item 2 */}
              <div className="WhyChooselist">
                <div className="WhyChooseIcon">
                  <img src={chooseIcon2} alt="Lending Partners Icon" />
                </div>
                <div className="WhyChooseUi">
                  <h4>100+ Lending Partners</h4>
                  <ul>
                    <li>Partner with the nation’s top lenders.</li>
                    <li>Access to hundreds of loan products.</li>
                    <li>We personally shop lenders for the best terms.</li>
                  </ul>
                </div>
              </div>

              {/* List Item 3 */}
              <div className="WhyChooselist">
                <div className="WhyChooseIcon">
                  <img src={chooseIcon3} alt="Nationwide Licensing Icon" />
                </div>
                <div className="WhyChooseUi">
                  <h4>Nationwide Licensing</h4>
                  <ul>
                    <li>We’re licensed in 49 states.</li>
                    <li>Personalized service with local expertise.</li>
                    <li>Wherever you live, we have you covered.</li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div> 
      </div> 
    </div> 
  );
};

export default WhyUs;
