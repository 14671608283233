import React from 'react';
import { Link } from 'react-router-dom';
import bannerImage from '../assets/images/banner1.jpg'; // Import the image

function BenifitBanner() {
  return (
    <div
      className="InnerBanner"
      style={{ backgroundImage: `url(${bannerImage})` }}
    >
      <div className="custom-container">
        <div className="InnerBannerWrapper">
          <h1>VA Loan Benefits</h1>
          <p>
            VA loans offer huge benefits such as 0% down payment, No PMI,
            lower rates, and reduced closing costs.
          </p>
          <div className="bannercta">
            <Link to="/quoteform" className="cta">Schedule a call</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BenifitBanner;
