import React from 'react';
import { Link } from 'react-router-dom';
import processimg1 from '../assets/images/processimg1.svg';
import processimg2 from '../assets/images/processimg2.svg';
import processimg3 from '../assets/images/processimg3.svg';
import processimg4 from '../assets/images/processimg4.svg';
import processimg5 from '../assets/images/processimg5.svg';

function InOurProcess() {
  return (
    <div className="OurProcess">
      <div className="custom-container">
        <div className="titleh2 aligncenter">
          <h2>
            The <span>The VA Loan</span> Process
          </h2>
        </div>
        <div className="OurProcessParent">
          {/* Step 1 */}
          <div className="OurProcessWrapper">
            <div className="OurProcessImg">
              <img src={processimg1} alt="Step 1 - Get Pre-Approved for a VA Loan" />
            </div>
            <div className="OurProcessContent">
              <div className="Countofnum">01</div>
              <h4>Get Pre-Approved for a VA Loan</h4>
              <p>
                Know how much you can afford. Prior to searching for a home, you need to get pre-qualified and obtain a
                pre-approval letter from your lender. Getting pre-approved involves assessing your financial situation
                and determining the loan amount you qualify for. You can get a pre-approval letter after completing
                your loan application. To know what you can afford, you can use our VA Loan Payment Calculator.
              </p>
              <Link to="/loan-calculator" className="ctawitharrow">
                VA Loan Payment Calculator
              </Link>
            </div>
          </div>
          {/* Step 2 */}
          <div className="OurProcessWrapper">
            <div className="OurProcessImg">
              <img src={processimg2} alt="Step 2 - Find a Home You Like" />
            </div>
            <div className="OurProcessContent">
              <div className="Countofnum">02</div>
              <h4>Find a Home You Like</h4>
              <p>
                Once you know how much you can afford, start exploring different home options. For that, you can find a
                trusted real estate agent. They can help you find homes in your budget in the area you want. Having a
                pre-approval letter puts you in the driver’s seat when you find a home you like. Besides, it shows the
                real estate agents and sellers that you are serious about buying.
              </p>
            </div>
          </div>
          {/* Step 3 */}
          <div className="OurProcessWrapper">
            <div className="OurProcessImg">
              <img src={processimg3} alt="Step 3 - Make an Offer" />
            </div>
            <div className="OurProcessContent">
              <div className="Countofnum">03</div>
              <h4>Make an Offer</h4>
              <p>
                When you find the perfect home, it’s time to make an offer, negotiate the terms of the sale, and sign a
                contract with the seller.
              </p>
            </div>
          </div>
          {/* Step 4 */}
          <div className="OurProcessWrapper">
            <div className="OurProcessImg">
              <img src={processimg4} alt="Step 4 - VA Appraisal and Underwriting" />
            </div>
            <div className="OurProcessContent">
              <div className="Countofnum">04</div>
              <h4>VA Appraisal and Underwriting</h4>
              <p>
                After signing the contract, your lender will order a VA appraisal to ensure the property meets fair
                market value and VA’s minimum property standards. This step is different from a home inspection.
                Meanwhile, underwriters will review your financial information and the appraisal. If everything checks
                out, you’ll be ready for closing.
              </p>
            </div>
          </div>
          {/* Step 5 */}
          <div className="OurProcessWrapper">
            <div className="OurProcessImg">
              <img src={processimg5} alt="Step 5 - Closing" />
            </div>
            <div className="OurProcessContent">
              <div className="Countofnum">05</div>
              <h4>Closing</h4>
              <p>
                At closing, you’ll need to sign all necessary legal documents and paperwork. Once completed, get the
                keys to your new home.
              </p>
              <Link to="/quoteform" className="ctawitharrow"> Apply for VA Loan Now</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InOurProcess;
