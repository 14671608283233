import React from 'react';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import servingImg1 from '../assets/images/servingimg1.svg';

function Serving() {
  const { ref, inView } = useInView({
    triggerOnce: true, // Animation triggers once
    threshold: 0.5, // When 50% of text is visible
  });

  // Smooth scroll to the top
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Smooth scroll to the "mortagesx" section
  const handleScroll = (e) => {
    e.preventDefault();
    const target = document.getElementById('mortagesx');
    if (target) {
      const offset = 80; // Offset from top
      const targetPosition = target.getBoundingClientRect().top + window.scrollY - offset;
      window.scrollTo({ top: targetPosition, behavior: 'smooth' });
    }
  };

  return (
    <div className="ServingServe">
      <div className="custom-container">
        <div className="ServingWrapper">
          {/* VA Loan Requirements Card */}
          <Link to="/benefit" onClick={handleScrollToTop} className="ServingCard">
            <div className="ServingImg">
              <img src={servingImg1} alt="Veteran Mortgage Requirements" />
            </div>
            <div className="ServingContent">
              <h4>VA Loan Requirements</h4>
              <p>Are you wondering if you qualify for VA loan benefits? We can help you find out.</p>
            </div>
          </Link>

          {/* Mortgage Loan Requirement Card */}
          {/* <a href="#mortagesx" onClick={handleScroll} className="ServingCard">
            <div className="ServingImg">
              <img src={servingImg1} alt="Veteran Loan Calculator" />
            </div>
            <div className="ServingContent">
              <h4>Mortgage Loan Requirement</h4>
              <p>Calculate monthly mortgage payment, taxes, and insurance, and plan a budget that fits your financial goals.</p>
            </div>
          </a> */}
        </div>
      </div>
    </div>
  );
}

export default Serving;
