import React from 'react';    
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import AbtBanner from '../../Components/abtbanner';
import CoreValue from '../../Components/corevalue';
import OurVision from '../../Components/ourvission';






function About() {
    return (
        <>
            <Header /> 
            <AbtBanner/>
            <OurVision/>
            <CoreValue/>
           
            <Footer /> 
        </>
    );
}

export default About;
