import React from 'react';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import Valonansbanner from '../../Components/valonsbanner';
import HowVAloansWork from '../../Components/howvaloanswork';
import InOurProcess from '../../Components/innourprocess';
function Valonans() {
  return (
    <>
      <Header /> {/* Render the Header component */}
      <Valonansbanner />
<HowVAloansWork />
<InOurProcess />
      <Footer />
    </>
  );

}

export default Valonans;