import React from 'react';    
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import EligiblityBanner from '../../Components/eligiblitybanner';
import EligiblitySteps from '../../Components/eligiblitysteps';




function Eligiblity() {
    return (
        <>
            <Header /> 
            <EligiblityBanner/>
            <EligiblitySteps/>
   

            <Footer /> 
        </>
    );
}

export default Eligiblity;

