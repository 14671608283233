import React from "react";
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import privacybanner from '../../assets/images/privacy.jpg'
const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <div className="InnerBanner"
        style={{ backgroundImage: `url(${privacybanner})`, backgroundSize: "cover", backgroundPosition: "center" }}
      >
        <div className="custom-container">
          <h1>Privacy Policy</h1>
        </div>
      </div>
      <div className="innerpage Privacypolicypage">
        <div className="custom-container">
          <div className="PrivacyPolicyWrapper">
            <section>
              <h4>Privacy Policy</h4>
              <p>
                This Privacy Policy governs the manner in which The American Loan Company LLC collects, uses, maintains and discloses information collected from users (each, a "User") of the www.veteran.com website ("Site"). This privacy policy applies to the Site and all products and services offered by The American Loan Company LLC.
              </p>
            </section>

            <section>
              <h4>Personal Identification Information</h4>
              <p>
                We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our site, register on the site, fill out a form, and in connection with other activities, services, features or resources we make available on our Site. Users may be asked for, as appropriate, name, email address, mailing address, phone number. Users may, however, visit our Site anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personal identification information, except that it may prevent them from engaging in certain Site-related activities.
              </p>
            </section>

            <section>
              <h4>Non-Personal Identification Information</h4>
              <p>
                We may collect non-personal identification information about Users whenever they interact with our Site. Non-personal identification information may include the browser name, the type of computer and technical information about Users' means of connection to our Site, such as the operating system and the Internet service providers utilized and other similar information.
              </p>
            </section>

            <section>
              <h4>Web Browser Cookies</h4>
              <p>
                Our Site may use "cookies" to enhance User experience. User's web browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. Users may choose to set their web browser to refuse cookies or to alert them when cookies are being sent. If they do so, note that some parts of the Site may not function properly.
              </p>
            </section>

            <section className="collected-info">
              <h4>How We Use Collected Information</h4>
              <p>
                The American Loan Company LLC may collect and use Users' personal information for the following purposes: </p>
              <ul>
                <li>
                  <strong>To improve customer service:</strong> Information you provide helps us respond to your customer service requests and support needs more efficiently.
                </li>
                <li>
                  <strong>To personalize user experience:</strong> We may use information in the aggregate to understand how our Users as a group use the services and resources provided on our Site.
                </li>
                <li>
                  <strong>To improve our Site:</strong> We may use feedback you provide to improve our products and services.
                </li>
                <li>
                  <strong>To process payments:</strong> We may use the information Users provide about themselves when placing an order only to provide service to that order. We do not share this information with outside parties except to the extent necessary to provide the service.
                </li>
                <li>
                  <strong>To send periodic emails:</strong> We may use the email address to send User information and updates pertaining to their order. It may also be used to respond to their inquiries, questions, and/or other requests.
                  <br />We value your privacy and the information you consent to share in relation to our SMS marketing service. We use this information to send you text notifications (for your order, including abandoned checkout reminders), text marketing offers, and transactional texts, including requests for reviews from us. Opt-in data and consent for text messaging will not be shared with any third parties except for messaging partners, for the purpose of enabling and operating our text messaging program.
                </li>
              </ul>

            </section>

            <section>
              <h4>How We Protect Your Information</h4>
              <p>
                We adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information, username, password, transaction information, and data stored on our Site.
              </p>
            </section>

            <section>
              <h4>Changes to This Privacy Policy</h4>
              <p>
                The American Loan Company LLC has the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.
              </p>
            </section>

            <section>
              <h4>Your Acceptance of These Terms</h4>
              <p>
                By using this Site, you signify your acceptance of this policy and terms of service. If you do not agree to this policy, please do not use our Site. Your continued use of the Site following the posting of changes to this policy will be deemed your acceptance of those changes.
              </p>
            </section>


          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
