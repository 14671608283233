import React from 'react';    
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import BenifitBanner from '../../Components/benifitbanner';
import KeyBenifit from '../../Components/keybenifit';



function Benefits() {
    return (
        <>
            <Header /> 
            
       <BenifitBanner/>
       <KeyBenifit/>
            <Footer /> 
        </>
    );
}

export default Benefits;
