import React from "react";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import { Link } from "react-router-dom";
import termscndbanner from '../../assets/images/privacy.jpg';

const TermsAndConditions = () => {
  const currentDate = new Date();
  const month = currentDate.toLocaleString("default", { month: "long" }); // e.g., "January"
  const year = currentDate.getFullYear(); // e.g., 2025

  return (
    <>
      <Header />
      <div
        className="InnerBanner"
        style={{
          backgroundImage: `url(${termscndbanner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="custom-container">
          <h1>Terms and Conditions</h1>
        </div>
      </div>
      <div className="innerpage Privacypolicypage">
        <div className="custom-container">
          <div className="PrivacyPolicyWrapper">
            <section>
              <h4>Please Read</h4>
              <p>
                These Terms of Use govern your use of this website. Please read
                these terms and conditions carefully before using this website.
                By accessing or using this website, you agree to be bound by
                these Terms of Use. If you do not agree to all of the terms,
                please immediately discontinue accessing this website.
              </p>
            </section>

            <section>
              <h4>Content</h4>
              <p>
                This website is owned and operated by the American Loan Company LLC and may provide general information about our and/or our subsidiaries’ products and services. Your eligibility for particular products and services is subject to our and/or our subsidiaries’ final determination, restrictions, and acceptance. We and/or our subsidiaries may discontinue or make changes to the information, products, licenses, or services described on this website at any time. Any dated information is published as of its publication date only. We and/or our subsidiaries do not undertake any obligation or responsibility to update or amend any such information. We and/or our subsidiaries reserve the right to terminate any or all offerings without prior notice. Furthermore, by offering information, products, or services via this website, no solicitation is made by us and/or our subsidiaries to any person to use such information, products, or services in jurisdictions where the provision of information, products, or services is prohibited by law.
              </p>
              <p>
              This website may contain links to third-party websites, such as social media websites, which we and/or our subsidiaries do not own or control, but which are provided for your convenience. If you visit a link to another website, you do so at your own risk subject to the terms and conditions established by the operator of that website. The fact that we and/or our subsidiaries provide a link to a website does not necessarily mean we and/or our subsidiaries endorse, authorize, or sponsor that third-party website, or that we are affiliated with the third-party website’s owners or sponsors. We and our subsidiaries reserve the right to terminate a link to a third-party website at any time. 

Although we and our subsidiaries try to provide accurate and timely information on this website, there may be inadvertent, technical, or factual inaccuracies and typographical errors. For these reasons, we and our subsidiaries cannot warrant the accuracy, completeness, or timeliness of the information, text, graphics, links, or other items on this website. 
              </p>
             
            </section>

            <section>
              <h4>Accessing the Website</h4>
              <p>
              You agree and acknowledge that you have the sole responsibility and liability for your use of this website and for providing or obtaining, and for maintaining, all of the hardware, software, electrical power, telecommunications, Internet services, and other products or services necessary or desirable for you to access and use this website. 

From time to time, due to maintenance, malfunctions, or other reasons, the website may not be available. We minimize such downtime but cannot be held responsible for losses resulting from the website's unavailability. We reserve the right to deny you access to the website at any time. 
              </p>
             
            </section>

            <section>
              <h4>SMS Consent</h4>
              <p>
              Providing your telephone contact information means you consent to receive SMS communications from us. You can unsubscribe at any time by replying with "STOP" or contacting us at <Link to="tel:+16823078370">+1 682-307-8370</Link>
              </p>
            </section>

            <section>
              <h4>Your Information</h4>
              <p>
              By submitting your information to us, you grant us permission to use it to fulfill your request for services. This includes copying, distributing, and reformatting your information as needed. We do not claim ownership of your materials but require this permission to process your transactions. 
              </p>
            </section>

            <section>
              <h4>Limitations of Liability</h4>
              <p>
              We and our subsidiaries are not liable for any direct, indirect, special, incidental, or consequential damages that may arise from your use of this website. This includes losses from service unavailability or technical issues. 
              </p>
              <p>
                The content on this website is provided "as is" without
                warranties of any kind, either express or implied.
              </p>
            </section>

            <section>
              <h4>Changes to these Terms of Use</h4>
              <p>
              We may update these Terms of Use periodically. Your continued use of the website constitutes your agreement to the updated terms. 

              </p>
              <p>Last Updated: {month} {year}</p>
            </section>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsAndConditions;
