import React from 'react';
import Eligiblity from '../Pages/Eligibility/eligibility';

function EligiblitySteps() {
  return (
    <>
      <div className="innerpage">
        <div className="custom-container">
          <div className="ContentCardWrapper">
            <div className="ContentCardParent">
              <div className="titleh2">
                <h2>The First Step To Get a VA Loan Is to Determine Your Eligibility.</h2>
              </div>
              <div className="ContentCardInner">
                <p>You may be eligible for VA loan if you are a veteran, active service member, surviving spouse of a Veteran, or the spouse of a Veteran missing in action or held as a prisoner of war (POW).</p>
                <h5>Service members</h5>
                <ul>
                  <li>Must have served at least 90 continuous days and received an honorable discharge.</li>
                </ul>
                <h5>For Wartime/Conflict Veterans</h5>
                <ul>
                  <li>World War II: September 16, 1940 – July 25, 1947</li>
                  <li>Korean War: June 27, 1950 – January 31, 1955</li>
                  <li>In the Republic of Vietnam: November 1, 1955 – May 7, 1975</li>
                  <li>Vietnam War: August 5, 1964 – May 7, 1975</li>
                  <li>Gulf War Period to Present: August 2, 1990 – Present</li>
                  <li>Persian Gulf War – Verify with the <a href="#" target="_blank">Veterans Administration Office</a></li>
                  <li>Afghanistan & Iraq – Verify with the <a href="#" target="_blank">Veterans Administration Office</a></li>
                </ul>
                <h5>Peacetime Service</h5>
                <ul>
                  <li>Post WWII Period: July 26, 1947 – June 26, 1950</li>
                  <li>Post Korean War: February 1, 1955 – August 4, 1964</li>
                  <li>Post-Vietnam War: May 8, 1975 – September 7, 1980</li>
                  <li>As an officer: October 17, 1981 – August 1, 1990</li>
                  <li>Enlisted veterans: September 8, 1980 – August 1, 1990</li>
                </ul>
                <h5>Reserve/National Guard Members</h5>
                <ul>
                  <li>Gulf War Period to Present: August 2, 1990 – Present</li>
                  <li>Any other period if you’ve served:</li>
                  <li>For at least 90 days of non-training active-duty service, or</li>
                  <li>For at least 90 days of active-duty service including at least 30 continuous days, or</li>
                  <li>6 years in the National Guard (or the Selected Reserve) with an honorable discharge or retired status</li>
                </ul>
              </div>
            </div>

            <div className="ContentCardParent">
              <div className="titleh2">
                <h2>What If I Don’t Meet Minimum Service Requirements?</h2>
              </div>
              <div className="ContentCardInner">
                <p>If you don't meet the basic service requirements, you may still qualify for a VA loan under certain conditions, such as discharge due to:</p>
                <ul>
                  <li>Hardship</li>
                  <li>Government convenience</li>
                  <li>Reduction in force</li>
                  <li>Medical conditions</li>
                  <li>Service-connected disabilities</li>
                  <li>Early out (with at least 21 months of a 2-year enlistment completed)</li>
                </ul>
                <p>To check your VA loan eligibility, talk to our VA loan specialist.</p>
              </div>
            </div>

            <div className="ContentCardParent">
              <div className="titleh2">
                <h2>Check Your VA Loan Eligibility Status</h2>
              </div>
              <div className="ContentCardInner">
                <p>To secure a VA loan, you’ll need to provide your lender with a Certificate of Eligibility (COE). This document confirms that you are eligible for VA loan.</p>
                <p>Obtaining a COE is often straightforward, with most lenders able to retrieve it using just your Social Security number and date of birth. In some cases, additional documentation for COE may be needed.</p>
                <p>Important Note: You don’t have to provide COE to your lender before applying for a VA loan.</p>
                <p>To check your VA loan eligibility, talk to our VA loan specialist.</p>
                <h5>Three Ways to Request a COE</h5>
                <ul>
                  <li>Through a VA-approved lender</li>
                  <li>Online via VA’s eBenefits portal</li>
                  <li>By mail with a VA form 26-1880</li>
                </ul>
                <p>Using the eBenefits portal can provide you with COE confirmation within a few minutes, whereas mailing in your application can take 4 to 6 weeks.</p>
              </div>
            </div>

            <div className="ContentCardParent">
              <div className="titleh2">
                <h2>Required Documents Based On Your Service Type</h2>
              </div>
              <div className="ContentCardInner">
                <p><strong>Active Duty:</strong> Statement of Service</p>
                <p><strong>Veteran:</strong> DD Form 214 (Report of Separation)</p>
                <p><strong>Current or Former Activated National Guard or Reserves:</strong> DD Form 214</p>
                <p><strong>Current National Guard or Reserves (Never Activated):</strong> Statement of Service and an allocated points statement</p>
                <p>Discharged National Guard (Never Activated): NGB Form 22 and NGB Form 23</p>
                <p><strong>Discharged Reserves (Never Activated)</strong></p>
                <ul>
                  <li><strong>Army Reserve:</strong> DARP Form FM 249-2E or ARPC Form 606-E</li>
                  <li><strong>Navy Reserve:</strong> NRPC 1070-124</li>
                  <li><strong>Air Force Reserve:</strong> AF 526</li>
                  <li><strong>Marine Corps Reserve:</strong> NAVMC HQ509 or NAVMC 798</li>
                  <li><strong>Coast Guard Reserve:</strong> CG 4174 or 4175</li>
                </ul>
              </div>
            </div>

            <div className="ContentCardParent">
              <div className="titleh2">
                <h2>Verifying VA Loan Eligibility for Spouses</h2>
              </div>
              <div className="ContentCardInner">
                <p>Surviving spouses need to obtain a COE to access VA loan benefits. Getting a COE for surviving spouses depends on whether or not they are receiving Dependency and Indemnity Compensation.</p>
                <ul>
                  <li><strong>Receiving Compensation:</strong> Submit VA Form 26-817 and a copy of Veteran’s separation paperwork (such as DD Form 214).</li>
                  <li><strong>Not Receiving Compensation:</strong> Apply using VA Form 21P-534EZ and submit the form to your state’s VA Pension Management Center.</li>
                </ul>
                <p>Additionally, you will also need to provide a copy of your marriage license, the Veteran’s death certificate (or DD Form 1300 – Report of Casualty), and the Veteran’s separation paperwork.</p>
                <p>For more details, surviving spouses can contact us to check their eligibility for VA loan benefits.</p>
              </div>
            </div>

            <div className="ContentCardParent">
              <div className="titleh2">
                <h2>VA Loan Lender Requirements</h2>
              </div>
              <div className="ContentCardInner">
                <p>In addition to meeting VA loan requirements set by the Department of Veterans Affairs (VA), you may also need to meet your lender’s criteria, which typically include:</p>
                <ul>
                  <li>An acceptable credit score</li>
                  <li>Reliable income to repay the loan</li>
                  <li>Manageable debt levels</li>
                </ul>
                <p>Ready to find out if you are eligible for a VA loan? Talk to our VA home loan specialist today.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EligiblitySteps;
