import React from 'react';
import faqbanner from '../assets/images/valoansbanner.jpg';

function FaqBanner() {
  return (
    <div className="InnerBanner" style={{ backgroundImage: `url(${faqbanner})` }}>
      <div className="custom-container">
        <div className="InnerBannerWrapper">
          <h1>Frequently Asked Questions on VA Loans</h1>
        </div>
      </div>
    </div>
  );
}

export default FaqBanner;
